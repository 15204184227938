import React from 'react'
import Fade from 'react-reveal/Fade';
import './Ita.css'
import { Helmet } from "react-helmet-async";

const Ita = () => {
    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
        <title>Individualize Treatment Alternatives - San Rafael Rehab</title>
        <link rel="canonical" href="https://sanrafaelrehab.org/individualize-treatment-alternatives" />
        </Helmet>

        <div className='ita'>
                <div className="ita-content">
                    <Fade top>
                        <h1>Tailoring Recovery for You</h1>
                        <h3>Addiction Treatment</h3>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Ita

import React from 'react'
import './Hero.css'
import { Link } from 'react-router-dom';
import { Link as LinkRoll } from "react-scroll";

import { Helmet } from "react-helmet-async";


const Hero = () => {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smoothly scroll to the top
      };

    return (
<> 

            <Helmet>
                <title>San Rafael Rehab | Addiction Recovery & Mental Health Center</title>
                <meta name="description" content="San Rafael Rehab offers personalized addiction treatment and mental health support in Marin County. Discover compassionate care and lasting recovery." />
                <link rel="canonical" href="https://sanrafaelrehab.com/" />
            </Helmet>

<div className='hero'>
    <div className="hero-container">

        <div className="content">
                <h1>Your First Step to Healing at San Rafael Rehab, Nestled in the <br />Heart of Marin County, CA.</h1>
                <h2>San Rafael Rehab</h2>
                <p>Our serene environment, expert care team, and evidence-based treatments are designed to help you safely transition into recovery.
                </p>

                <div className='heroBtn'>
                    <Link to='/contact' onClick={scrollToTop}>
                        <button>Find Help Now</button>
                    </Link>

                    <LinkRoll activeClass="active" to="LearnMore" onClick={scrollToTop} spy={true} smooth={true} duration={500}>
                        <button>Learn More</button>
                    </LinkRoll>

                </div>


        </div>
    </div>
</div>
</>

    )
}

export default Hero
import React from "react";
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet-async";
import './Insurance.css'
import family from "../../assets/family.webp";


const Insurance = () => {

    return (
            <>
            <Helmet>
            <meta charSet="utf-8" />
            <title> Insurance - San Rafael Rehab</title>
            <meta name="description" content="Discover San Rafael Rehab's insurance coverage options for addiction and mental health treatment. We accept most major providers—get help today with verified benefits." />
            <link rel="canonical" href="https://sanrafaelrehab.org/insurance" />
            </Helmet>

            <div className='insurance main-menu'>

                        <div className="insurance-content container">
                        <div className='family'>
                            <img src={family} alt="Calm man in front of the sea" loading="lazy" />
                        </div>

                        <Fade top>
                        <div className="insurance-caption">
                            <h1>INSURANCE</h1>
                            <p>At San Rafael Rehab, we understand the importance of accessible and affordable care for individuals seeking treatment for substance abuse. That's why we work with a variety of insurance providers to ensure that our services are available to as many people as possible.</p>
                        </div>
                        </Fade>
                        </div>
                    </div>



            </>
    )

    }
export default Insurance





import React from 'react'
import Fade from 'react-reveal/Fade';
import './Mat.css'
import { Helmet } from "react-helmet-async";

const Mat = () => {
    return (
    <>
            <Helmet>
                <title>Medication Assisted Treatment - San Rafael Rehab</title>
                <meta name="description" content="Explore Medication Assisted Treatment (MAT) at San Rafael Rehab. Our compassionate team provides effective treatment for substance use disorders with FDA-approved medications, counseling, and support for lasting recovery. Start your healing journey today." />
            </Helmet>

        <div className='mat'>
                <div className="mat-content">
                    <Fade top>
                        <h1>Medication Assisted Treatment (MAT)</h1>
                        <h3>Addiction Treatment</h3>
                    </Fade>
            </div>
        </div>
    </>
    )
}

export default Mat

import React from 'react'
import Fade from 'react-reveal/Fade';
import './DetoxBenzodiazepines.css'
import { Helmet } from "react-helmet-async";

const DetoxBenzodiazepines = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Benzodiazepines Detox - San Rafael Rehab</title>
            <link rel="canonical" href="https://sanrafaelrehab.org/detox-benzodiazepines" />
        </Helmet>

        <div className='detox-benzodiazepines'>

                <div className="detox-benzodiazepines-content">
                    <Fade top>
                    <h1>Benzodiazepines</h1>
                    <h2>Detox Program</h2>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default DetoxBenzodiazepines

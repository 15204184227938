import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import App from './App';

import DetoxPage from './routes/DetoxPage';
import AboutPage from './routes/AboutPage';
import InsurancePage from './routes/InsurancePage';
import ContactPage from './routes/ContactPage';

import COCAINE from './routes/COCAINE';
import ALCOHOL from './routes/ALCOHOL';
import FENTANYL from './routes/FENTANYL';
import HEROIN from './routes/HEROIN';
import KRATOM from './routes/KRATOM';
import MDMA from './routes/MDMA';
import METH from './routes/METH';
import OPIATE from './routes/OPIATE';
import OPIOID from './routes/OPIOID';
import OXYCODONE from './routes/OXYCODONE';
import PRESCRIPTIONS from './routes/PRESCRIPTIONS';
import XANAX from './routes/XANAX';
import BENZODIAZEPINES from './routes/BENZODIAZEPINES';
import STIMULANT from './routes/STIMULANT';

import MAT from './routes/MAT';
import ITA from './routes/ITA';

import DetoxHeroin from './routes/DetoxHeroin';
import DetoxAlcohol from './routes/DetoxAlcohol';
import DetoxCocaine from './routes/DetoxCocaine';
import DetoxBenzodiazepines from './routes/DetoxBenzodiazepines';
import DetoxMethamphetamine from './routes/DetoxMethamphetamine';
import DetoxMarijuana from './routes/DetoxMarijuana';
import DetoxAdderall from './routes/DetoxAdderall';

import Jobs from './routes/JOBS';
import SA from './routes/SA';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <HelmetProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/alcohol" element={<ALCOHOL />} />
        <Route path="/cocaine" element={<COCAINE />} />
        <Route path="/fentanyl" element={<FENTANYL />} />
        <Route path="/heroin" element={<HEROIN />} />
        <Route path="/kratom" element={<KRATOM />} />
        <Route path="/mdma" element={<MDMA />} />
        <Route path="/meth" element={<METH />} />
        <Route path="/opiate" element={<OPIATE />} />
        <Route path="/opioid" element={<OPIOID />} />
        <Route path="/oxycodone" element={<OXYCODONE />} />
        <Route path="/prescriptions" element={<PRESCRIPTIONS />} />
        <Route path="/xanax" element={<XANAX />} />
        <Route path="/benzodiazepines" element={<BENZODIAZEPINES />} />
        <Route path="/stimulant" element={<STIMULANT />} />
        
        <Route path="/medication-assisted-treatment" element={<MAT />} />
        <Route path="/individualize-treatment-alternatives" element={<ITA />} />
        
        <Route path="/detox-heroin" element={<DetoxHeroin />} />
        <Route path="/detox-alcohol" element={<DetoxAlcohol />} />
        <Route path="/detox-cocaine" element={<DetoxCocaine />} />
        <Route path="/detox-benzodiazepines" element={<DetoxBenzodiazepines />} />
        <Route path="/detox-methamphetamine" element={<DetoxMethamphetamine />} />
        <Route path="/detox-marijuana" element={<DetoxMarijuana />} />
        <Route path="/detox-adderall" element={<DetoxAdderall />} />
        
        <Route path="/substance-abuse" element={<SA />} />
        
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/detox" element={<DetoxPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/insurance" element={<InsurancePage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </BrowserRouter>
  </HelmetProvider>
);

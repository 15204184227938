import React from "react";
import Fade from 'react-reveal/Fade';
import { Helmet } from "react-helmet-async";
import './Contact.css'
import Team from "../../assets/contact-us-hero.webp";


const Contact = () => {

    return (
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>San Rafael Rehab Phone Number | Contact Our Recovery Team</title>
                <meta name="description" content="Reach out to San Rafael Rehab today. Call our dedicated helpline to speak with our admissions specialists and get started on the road to recovery." />
                <link rel="canonical" href="https://sanrafaelrehab.org/contact" />
            </Helmet>

            <div className='contact main-menu'>

                        <div className="contact-content container">
                        <div className='team'>
                            <img src={Team} alt="Calm man in front of the sea" loading="lazy" />
                        </div>

                        <Fade top>
                        <div className="contact-caption">
                            <h1>HAVE SOME QUESTIONS?</h1>
                        </div>
                        </Fade>
                        </div>
                    </div>



            </>
    )

    }
export default Contact





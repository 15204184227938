import React from 'react'
import Fade from 'react-reveal/Fade';
import './Detox.css'

import { Helmet } from "react-helmet-async";

const Detox = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Urgent Detox Program in San Rafael | Same-Day Admission Available</title>

                <meta name="description" content="Need immediate detox? Our urgent detox program in San Rafael offers fast, safe, and medically supervised treatment to start your recovery now." />
                <link rel="canonical" href="https://sanrafaelrehab.org/detox" />
            </Helmet>

        <div className='detox'>
                    <div className="detox-content">
                        <Fade left>
                            <div>
                                <h1>DETOX PROGRAMS</h1>
                            </div>
                        </Fade>

                    </div>
                </div>
        </>
    )
}

export default Detox
import React from 'react'
import Fade from 'react-reveal/Fade';
import './Prescriptions.css'
import { Helmet } from "react-helmet-async";

const Prescriptions = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Prescriptions Addiction - San Rafael Rehab</title>
            <link rel="canonical" href="https://sanrafaelrehab.org/prescriptions" />
        </Helmet>

        <div className='prescriptions'>
                <div className="prescriptions-content">
                    <Fade top>
                    <h1>PRESCRIPTIONS ADDICTION</h1>
                    <h3>SUBSTANCE ABUSE</h3>

                    </Fade>
            </div>
        </div>
        </>
    )
}

export default Prescriptions

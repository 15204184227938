import React from 'react'
import Fade from 'react-reveal/Fade';
import './DetoxAdderall.css'
import { Helmet } from "react-helmet-async";

const DetoxAdderall = () => {
    return (
        <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Adderall Detox - San Rafael Rehab</title>
            <link rel="canonical" href="https://sanrafaelrehab.org/detox-adderall" />
        </Helmet>

        <div className='detox-adderall'>

                <div className="detox-adderall-content">
                    <Fade top>
                    <h1>Adderall Detox</h1>
                    <h2>Detox Program</h2>
                    </Fade>
            </div>
        </div>
        </>
    )
}

export default DetoxAdderall

import React from "react";
import Fade from 'react-reveal/Fade';
import './About.css'
import { Helmet } from "react-helmet-async";


const About = () => {

    return (
            <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>About Us - San Rafael Rehab</title>
                <meta name="description" content="Discover San Rafael Rehab’s compassionate approach to addiction recovery and mental health treatment. Learn about our expert team, personalized programs, and commitment to lifelong healing in Marin County, CA." />
                <link rel="canonical" href="https://sanrafaelrehab.org/about-us" />
            </Helmet>



            <div className='about main-menu'>

                        <div className="about-content">
                            <Fade top>
                                <h1>ABOUT US</h1>
                            </Fade>
                        </div>
                    </div>


            </>
    )

    }
export default About
